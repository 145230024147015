<template>
  <div>
    <AdminPasswordEntry
      v-if="isPasswordStep"
      data-test-id="adminPasswordEntry"
      @changeStep="handleComponentChange"
    />
    <AdminEmailEntry
      v-else
      data-test-id="adminEmailEntry"
      @changeStep="handleComponentChange"
      :onError="onCredentialError"
    />
  </div>
</template>

<script>
import AdminEmailEntry from 'client/containers/admin/login/AdminEmailEntry.vue';
import AdminPasswordEntry from 'client/containers/admin/login/AdminPasswordEntry.vue';

import { LocalStorageConsts } from 'client/util/Constants';
import { mapStores } from 'pinia';
import Cookies from 'js-cookie';
import LocalStorageService from 'client/util/LocalStorageService';
import LoginConsts from 'client/util/Constants/LoginConstants';
import UserLoginStore from 'client/stores/UserLoginStore';
import loginUtil from 'client/components/mixins/loginUtil';
import routerService from 'client/services/routerService';

const {
  clientType,
  clientTypeCookie,
  responseMessages,
} = LoginConsts;

const {
  userLoginEmail,
} = LocalStorageConsts;

const {
  loginError,
} = responseMessages;

export default {
  name: 'AdminLoginController',

  mixins: [
    loginUtil,
  ],

  props: {
    clientType: {
      type: String,
      default: clientType.admin,
    },
  },

  components: {
    AdminEmailEntry,
    AdminPasswordEntry,
  },

  computed: {
    ...mapStores(UserLoginStore),

    isPasswordStep() {
      return this.$route.query.step === 'password';
    },
  },

  beforeRouteUpdate(to, from, next) {
    if (to.query && to.query.step) {
      const hasQuery = from.query;
      // no query and/or no previous step signifies email page
      const isComingFromEmailEntry = !(hasQuery && from.query.step);

      switch (to.query.step) {
        case 'password':
          // if going to password page but not from email entry, redirect to email entry
          // redirect is important as it will prevent the user from using browser forward button
          // params are not carried over on back button, so !to.params.error is always false
          // when !to.params.error true
          if (!isComingFromEmailEntry && !to.params?.error) {
            this.userLoginStore.password = '';
            this.interceptNavigation(from, next);
            return;
          }
          break;
        default:
      }
    }
    next();
  },

  methods: {
    handleComponentChange(step, paramError = '') {
      // append the step for everything but email page
      const stepParam = step ? { step } : {};

      routerService.goToAdminLogin({
        params: { error: paramError },
        query: { ...stepParam },
      });
    },

    onCredentialError(error) {
      const message = error.message || loginError;
      // TODO the exact error when email is wrong is pending to be discussed
      if (message === responseMessages.access_denied) {
        routerService.goToAdminLogin({
          params: { error: message },
          query: {
            time: new Date().getTime().toString(),
          },
        });
      }
    },

    populateEmailField() {
      const savedEmail = LocalStorageService.getItem(userLoginEmail) || '';
      const userEmail = this.userLoginStore.email || savedEmail;
      const shouldRememberEmail = !!(this.userLoginStore.shouldRememberEmail || savedEmail);

      this.userLoginStore.email = userEmail;
      this.userLoginStore.shouldRememberEmail = shouldRememberEmail;
    },

    validateInitialStep() {
      // could navigate straight to password step if saved if bookmarks without email saved
      const isInitialStepInvalid = this.$route.query.step && !this.userLoginStore.email;

      if (isInitialStepInvalid) {
        routerService.goToAdminLogin();
      }

      this.populateEmailField();
    },
  },

  mounted() {
    Cookies.set(clientTypeCookie, 'admin');
    this.validateInitialStep();
  },
};
</script>

<style module>
@import '../../css/login-components.css';
</style>
