
// Components
import JumpcloudGoButton from 'client/components/JumpcloudGoButton.vue';
import JumpcloudGoPageLoader from 'client/components/JumpcloudGoPageLoader.vue';
import LoginActionButton from 'client/components/LoginActionButton.vue';

// Libraries
import { defineComponent } from 'vue';

// Constants
import { accessResponse, durtApiResponseType, infoResponse } from 'client/services/durtConstants';
import LoginConsts from 'client/util/Constants/LoginConstants';
import MfaConstants from 'client/containers/Login/MfaConstants';

// Store
import { mapActions, mapGetters } from 'vuex';

// Services
import durtService from 'client/services/durtService';
import routerService from 'client/services/routerService';

// Utils
import { useLoginLabels } from 'client/util/Constants/LoginLabels';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';
import Util from 'client/util/Util';

const { userConsoleRootRoute } = LoginConsts;
const { mfaNames } = MfaConstants;

export default defineComponent({
  name: 'JumpcloudGoHandler',

  components: {
    JumpcloudGoButton,
    JumpcloudGoPageLoader,
    LoginActionButton,
  },

  data() {
    return {
      durtInfo: {} as infoResponse,
      durtAccess: {} as accessResponse,
      shouldShowLoader: false,
      isButtonLoading: true,
      shouldShowContinueButton: false,
    };
  },

  props: {
    mfaType: {
      type: String,
      required: true,
    },
  },

  setup() {
    const uiLabel = useLoginLabels();
    const mfaLabels = useMfaLabels();

    return {
      uiLabel,
      mfaLabels,
    };
  },

  computed: {
    ...mapGetters('StepUpModel', [
      'stateId',
    ]),

    headingText() {
      return this.mfaLabels.verifyIdentity;
    },

    isUvRequired() {
      return this.mfaType === mfaNames.durt_uv || !!this.durtInfo.is_locked;
    },

    userEmail(): string {
      return this.durtInfo.email || '';
    },
  },

  created() {
    this.initDurt();
  },

  methods: {
    ...mapActions('StepUpModel', [
      'setStateId',
    ]),

    async durtSignin() {
      try {
        const { isUvRequired, stateId } = this;
        this.setButtonState(true);
        this.setLoaderState(!isUvRequired);

        try {
          this.durtAccess = await durtService.getAccess(isUvRequired, stateId);
          this.setLoaderState(true);
        } catch {
          this.handleError();
        }

        if (this.durtAccess.session) {
          // show continue button to force extra click for android users
          if (this.durtInfo.result === durtApiResponseType.userNotActive) {
            await Util.delay(2000);
            this.setButtonState(false);
            this.setLoaderState(false);
            this.shouldShowContinueButton = true;
          } else {
            await Promise.all([durtService.signin(this.durtAccess), Util.delay(2000)]);

            this.$emit('success');
          }
        }
      } catch {
        window.location.replace(`${userConsoleRootRoute}#/?error=goError`);
      }
    },

    async handleError() {
      try {
        await durtService.signout();
      } catch {
        // expect to fail with 401 Unauthorized
        this.setStateId('');
        routerService.replaceToUserLogin();
      }
    },

    async initDurt() {
      if (await durtService.hasExtension()) {
        try {
          this.durtInfo = await durtService.getInfo();
        } catch (e: any) {
          if (e.message === durtApiResponseType.userNotActive) {
            this.setButtonState(false);
            this.durtInfo.result = e.message;
          } else {
            this.handleError();
          }
          return;
        }
      }

      if (this.durtInfo.result === durtApiResponseType.success) {
        this.durtSignin();
      } else {
        this.handleError();
      }
    },

    async handleSignin() {
      try {
        this.setButtonState(true);
        await durtService.signin(this.durtAccess);

        this.$emit('success');
      } catch {
        window.location.replace(`${userConsoleRootRoute}#/?error=goError`);
      }
    },

    setLoaderState(value: boolean) {
      this.shouldShowLoader = value;
    },

    setButtonState(value: boolean) {
      this.isButtonLoading = value;
    },
  },
});
