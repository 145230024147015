import { datadogRum } from '@datadog/browser-rum-slim';
import ConfigService from 'client/util/initializationHandler/ConfigService';

const appVersion = process.env.VERSION || '0.0.0';

export default {
  initialize(params = {}) {
    const { serviceName } = params;
    const applicationId = ConfigService.ddAppId;
    const clientToken = ConfigService.ddClientId;
    const sessionSampleRate = ConfigService.ddSampleRate || 10;
    const version = appVersion;

    return datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service: serviceName,
      version,
      sessionSampleRate,
      sessionReplaySampleRate: 0,
      trackInteractions: false,
      trackResources: false,
      trackLongTasks: false,
    });
  },
};
