import ConfigService from 'client/util/initializationHandler/ConfigService';
import Constants, { LoginConsts } from 'client/util/Constants';

const { routeGeneratorError, userConsoleRootRoute } = LoginConsts;
const { admin, user } = LoginConsts.clientType;
const { xsrf } = Constants;

// Constant for RouterOptions object
export const VueRouterMode = 'history';

/**
 * The keys of the legacy routes object is the value of the template parameter.
 * componentName is the name of the Vue component that is rendered for that route.
 * clientType is a prop that will be passed to the vue component to determine api calls.
 */
export const LegacyQueryRoutes = {
  adminLogin: {
    clientType: admin,
    componentName: 'AdminLoginWrapper',
  },

  adminPasswordResetForm: {
    clientType: admin,
    componentName: 'AdminPasswordResetAndSetup',
  },

  adminTOTPResetForm: {
    clientType: admin,
    componentName: 'AdminTOTPReset',
  },

  chooseOS: {
    clientType: user,
    componentName: 'ChooseOS',
  },

  loginMFA: {
    clientType: admin,
    componentName: 'VerifyTOTP',
  },

  oauth: {
    clientType: user,
    componentName: 'OauthLoginWrapper',
    csrfType: xsrf,
  },

  resetMacPassword: {
    componentName: 'MacPasswordReset',
  },

  resetUserPassword: {
    clientType: user,
    componentName: 'RequestPasswordReset',
  },

  resetAdminPassword: {
    clientType: admin,
    componentName: 'RequestPasswordReset',
  },

  resetAdminTOTP: {
    clientType: admin,
    componentName: 'RequestTOTPReset',
  },

  resetPasswordAdminForm: {
    clientType: admin,
    componentName: 'AdminPasswordResetAndSetup',
  },

  resetPasswordAdminMFA: {
    clientType: admin,
    componentName: 'AdminTotpSetup',
  },

  resetPasswordUserForm: {
    clientType: user,
    componentName: 'UserResetPasswordController',
  },

  resetWindowsPassword: {
    componentName: 'WindowsPasswordReset',
  },

  sso: {
    clientType: user,
    componentName: 'SsoLoginWrapper',
    csrfType: xsrf,
  },

  userActivation: {
    clientType: user,
    componentName: 'UserRegistration',
  },

  userLogin: {
    clientType: user,
    componentName: 'UserLoginWrapper',
  },

  default: {
    componentName: 'UserLoginWrapper',
  },

};

/**
 * Gets the window origin. window.origin does not work properly in some versions of microsoft edge.
 * This is added to build that origin url if the browser does not support window.origin or
 * window.location.origin. See the following link for more
 * details: https://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
 * @return {String} [the origin of the url]
 */
export const getOrigin = () => {
  let origin = window.origin || window.location.origin;
  if (!origin) {
    const { hostname, protocol, port } = window.location;
    origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  }
  return origin;
};

/**
 * Sinon does not allow spies or stubs for standalone exported functions. Therefore, the helper
 * object was created so the validateArgs method can be tested. This is used to validate non
 * optional parameters passed to route generator functions.
 */
export const routeGeneratorHelpers = {
  validateArgs: (...args) => {
    if (!args.length) {
      throw new Error(routeGeneratorError);
    }
    args.forEach((arg) => {
      if (!arg) {
        throw new Error(routeGeneratorError);
      }
    });
  },
};

/**
 * the routeGenerator should be used to keep track of the routes used across
 * the login pages. This was created in order to avoid any potential spelling
 * mistakes when redirecting.
 */
export default {
  adminOidcConsent: '/login/admin/consent',
  adminTotpSetup: '/login/admin/totp',
  duoCallback: '/login/duo/oauth/callback',
  googleLogin: '/oauth/google/login',
  jumpcloudClientOidcCallback: '/login/oidc_callback',
  login: '/login',
  loginAdmin: '/login/admin',
  loginProviderNotLinked: '/login/provider-not-linked',
  oauth: 'oauth',
  signup: '/signup',
};

// Admin Routes
export const AdminRoutes = {
  dashboard: '/',
  logout: '/logout',
  mtp: '/mtp',
  qrCode: '/totp-qr-gen',
  resetMacPassword: '/login?template=resetMacPassword',
  resetPassword: '/login?template=resetAdminPassword',
  resetTOTP: '/login?template=resetAdminTOTP',
  resetUserPassword: '/login?template=resetPasswordUserForm',
  resetWindowsPassword: '/login?template=resetWindowsPassword',
  verifyMFA: '/login?template=loginMFA',
};

// User Routes
export const UserRoutes = {
  dashboard: userConsoleRootRoute,

  /**
   * Builds the route that will redirect the user to the OAuth endpoint they are trying to
   * access.
   * TODO(cwpinkerton): update if OAuth gets its own domain
   * @param {String} redirectTo [oauth endpoint path the user is attempting to login in to extracted
   *                             from the query params in LoginRouter]
   * @returns {String} [the url to the user MFA page with the redirectTo passed as query params]
   */
  oauthRedirectUrl(redirectTo) {
    routeGeneratorHelpers.validateArgs(redirectTo);
    const oauthOrigin = ConfigService.oauthRedirectUrl;

    return redirectTo.startsWith(oauthOrigin)
      ? redirectTo
      : `${oauthOrigin}${redirectTo}`;
  },

  /**
   * Generates the string that will be used to create the data url for the user qr code. The qr code
   * is generated using a client side qr code generator.
   * @param  {String} email [users email]
   * @param  {String} totp  [totp key from the email]
   * @return {String}       [the value that will be passed to the client side qr code generator]
   */
  qrCodeValue(email, totp) {
    routeGeneratorHelpers.validateArgs(email, totp);
    return `otpauth://totp/${email}?secret=${totp}&issuer=JumpCloud%20User`;
  },

  resetPassword: 'login?template=resetUserPassword',

  resetPasswordUserForm: '/login?template=resetPasswordUserForm',

  /**
   * Route for the start of the MFA flow. Will be subject to change when reseting MFA does not rely
   * on reseting their passsword.
   * @param  {String} totpKey [totpKey provided from the reset password email]
   * @param  {String} email   [email of the user]
   * @return {String}         [setup mfa route]
   */
  setupMFA(totpKey, email) {
    routeGeneratorHelpers.validateArgs(totpKey, email);
    return `/login?template=resetPasswordUserMFA&totp=${totpKey}&email=${email}`;
  },

  /**
   * Builds the route that will redirect the user to the application they are trying to access with
   * SSO. Can handle full redirectTo url or application path only
   * (e.g. https://sso.jumpcloud.local/saml2/iamshowcase or saml2/iamshowcase)
   * @param {String} redirectTo [application path the user is attempting to login in to extracted
   *                             from the query params in SsoLoginWrapper]
   * @returns {String} [the url to the user MFA page with the redirectTo passed as query params]
   */
  ssoRedirectUrl(redirectTo) {
    routeGeneratorHelpers.validateArgs(redirectTo);
    const ssoOrigin = ConfigService.ssoRedirectUrl;

    return redirectTo.startsWith(ssoOrigin)
      ? redirectTo
      : `${ssoOrigin}${redirectTo}`;
  },

  xsrfUrl: `${userConsoleRootRoute}/xsrf`,

  deviceCert() {
    return ConfigService.deviceCertUrl;
  },
};
