
import { LoginConsts } from 'client/util/Constants';
import { defineComponent } from 'vue';
import { useLoginLabels } from 'client/util/Constants/LoginLabels';
import LoginActionButton from 'client/components/LoginActionButton.vue';
import Util from 'client/util/Util';

const { userConsoleRootRoute } = LoginConsts;

// This component is specifically for the Android Mobile Device Trust SSO flow
// The extra click provided by this button satisfies Android's user interaction
// requirements for deep links. See https://jumpcloud.atlassian.net/browse/ZR-1076
export default defineComponent({
  name: 'SSOGoContinue',

  components: {
    LoginActionButton,
  },

  setup() {
    const uiLabel = useLoginLabels();
    return { uiLabel };
  },

  props: {
    controllingQueryParams: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    submit() {
      this.isLoading = true;
      Util.redirect(this.controllingQueryParams.redirectTo || userConsoleRootRoute);
    },
  },
});
