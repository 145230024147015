import { defineStore } from 'pinia';

export interface UserLoginState {
  email: string;
  password: string;
  shouldRememberEmail: boolean;
  verifyEmailHash?: string;
}

export default defineStore('userLogin', {
  // arrow function recommended for full type inference
  state: (): UserLoginState => ({
    email: '',
    password: '',
    shouldRememberEmail: false,
    verifyEmailHash: '',
  }),
});
