export interface Extension {
  id: string,
  send: <T>(extensionId: string, payload: any) => Promise<T>,
}

export interface basicResponse {
  result: string,
}

export interface infoResponse extends basicResponse {
  email?: string,
  // eslint-disable-next-line camelcase
  is_locked?: boolean,
  name?: string,
  photo?: string,
}

export interface accessResponse extends basicResponse {
  session: string,
  pkceKey: string,
}

interface StringByString {
  [key: string]: string;
}

export const durtEvents = {
  access: {
    error: 'Error occurred while creating DURT access request.',
  },
  info: {
    error: 'Error occurred during DURT validation.',
  },
  register: {
    error: 'Error occurred during Jumpcloud Go registration.',
    success: 'Successful Jumpcloud Go registration.',
  },
  signin: {
    error: 'Error occurred during Jumpcloud Go signin.',
    success: 'Successful Jumpcloud Go signin.',
  },
};

export const durtStatus = {
  loggedIn: 'LOGGED_IN',
  locked: 'LOCKED',
  notRegistered: 'USER_NOT_REGISTERED',
};

export const durtApiResponseType = {
  notSupportedError: 'NOT_SUPPORTED_ERROR',
  success: 'SUCCESS',
  unexpectedError: 'UNEXPECTED_ERROR',
  userNotRegisteredError: 'USER_NOT_REGISTERED_ERROR',
  userNotActive: 'USER_NOT_ACTIVE',
};

export const command = {
  access: 'access',
  info: 'info',
  logout: 'logout',
  register: 'register',
};

export const extensionResponseType = {
  commandNotFound: 'COMMAND_NOT_FOUND',
  messagingError: 'NATIVE_MESSAGING_ERROR',
  success: 'SUCCESS',
  unexpectedError: 'BE_UNEXPECTED_ERROR',
};

export const durtOidcScopes = [
  'device',
  'email',
  'openid',
  'organization',
  'profile',
];

export const androidDeepLinks: StringByString = {
  local: 'local.jumpcloud.go.login',
  staging: 'stg01.jumpcloud.go.login',
  production: 'com.jumpcloud.go.login',
};

export const androidAppIds: StringByString = {
  local: 'com.jumpcloud.JumpCloud_Protect.local',
  staging: 'com.jumpcloud.JumpCloud_Protect.staging',
  production: 'com.jumpcloud.JumpCloud_Protect',
};
