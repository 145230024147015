<!-- eslint-disable vue/no-mutating-props -->
<template>
  <form
    :action="action"
    autocomplete="on"
    method="post"
  >
    <LoginTextField
      :autoFocus="autofocus === 'email'"
      @focus="updateAutofocus('email')"
      label="Email"
      name="email"
      :placeholder="emailPlaceholder"
      required
      :submitOnEnter="submit"
      type="email"
      vvName="email"
      v-model="form.email"
    />
    <LoginTextField
      :autoFocus="autofocus === 'password'"
      :autocomplete="false"
      @focus="updateAutofocus('password')"
      label="Password"
      name="password"
      placeholder="Password"
      required
      :submitOnEnter="submit"
      type="password"
      vvName="password"
      v-model="form.password"
    />
    <input
      v-if="token"
      :name="token.name"
      type="hidden"
      :value="token.value"
    >
    <LoginActionButton
      :action="submit"
      data-automation="loginButton"
      :isLoading="isLoading"
      type="submit"
    >
      {{ submitButtonText }}
    </LoginActionButton>
  </form>
</template>
<script>
import LoginActionButton from 'client/components/LoginActionButton.vue';
import LoginTextField from 'client/components/LoginTextField.vue';

export default {
  name: 'Credentials',
  props: {
    action: {
      type: String,
      required: true,
    },
    autofocus: {
      type: String,
      default: 'email',
    },
    form: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Function,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    token: {
      type: Object,
      default: null,
    },
    /**
     * used to update the last input so when the user switches clientTypes it does not affect
     * their place in the form.
     */
    updateAutofocus: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    LoginActionButton,
    LoginTextField,
  },
  computed: {
    emailPlaceholder() {
      return this.isAdmin ? 'Administrator Email Address' : 'User Email Address';
    },
  },
};
</script>
