import * as LaunchDarklyClient from 'launchdarkly-js-client-sdk';
import { isEmpty } from 'lodash';
import ConfigService from 'client/util/initializationHandler/ConfigService';
import LocalStorageConstants from 'client/util/Constants/LocalStorageConstants';
import LocalStorageService from 'util/LocalStorageService';
import Vue from 'vue';
import shajs from 'sha.js';

const { localStorageOrgKey, optimizelyVisitorId } = LocalStorageConstants;

/**
 * These are the names of all feature flags that are available to the application. They are kept
 * here, in the FeatureFlagsModule so that they can be imported and used instead of repeating
 * magic strings throughout the application when feature flags are needed.
 */
export const featureFlags = {
  adminIdFirst: 'adminIdFirst',
};
/**
 * This hash maps beta feature keys used by components to feature flag names used in the feature
 * management provider. It allows for the overriding of existing Mongo feature flags with
 * LaunchDarkly feature flags and the use of more succinct names for LaunchDarkly feature flags.
 */
export const featureFlagToProviderMap = {
  [featureFlags.adminIdFirst]: '2024-07-01-admin-id-first',
};

export function createFeatureFlagsModule() {
  return {
    namespaced: true,

    state: {
      betaFeatures: {},
    },

    actions: {
      fetchFeatureFlags({ commit }) {
        return new Promise((resolve) => {
          const { launchDarklyClientSideId, launchDarklyBaseUrls } = ConfigService;

          // default to stored id for phased roll-out
          const id = LocalStorageService.getValue(localStorageOrgKey);
          const hashedId = id ? shajs('sha256').update(String(id)).digest('hex') : '';

          // optimizely saves the visitorId in hashed form
          const visitorId = LocalStorageService.getValue(optimizelyVisitorId) || '';

          const key = hashedId || visitorId;
          const payload = { key };

          const options = {
            streaming: false,
          };

          if (!isEmpty(launchDarklyBaseUrls)) {
            Object.entries(launchDarklyBaseUrls).forEach(([optionKey, optionValue]) => {
              // Only set the config values if an explicit value was given,
              // so that the default value is controlled by the LD SDK and not us.
              if (optionValue !== undefined && optionValue !== null) {
                options[optionKey] = optionValue;
              }
            });
          }

          const ldClient = LaunchDarklyClient
            .initialize(launchDarklyClientSideId, payload, options);

          ldClient.on('ready', () => {
            Object.keys(featureFlagToProviderMap).forEach((flagKey) => {
              const flag = featureFlagToProviderMap[flagKey];
              const value = ldClient.variation(flag, undefined);

              if (value !== undefined) {
                commit('UPDATE_BETA_FEATURES', { [flagKey]: value });
              }
            });

            resolve();
          });
        });
      },
    },

    getters: {
      betaFeatures(state) {
        return state.betaFeatures;
      },
    },

    mutations: {
      UPDATE_BETA_FEATURES(state, payload) {
        Object.keys(payload).forEach((key) => {
          Vue.set(state.betaFeatures, key, payload[key]);
        });
      },
    },
  };
}

export default createFeatureFlagsModule();
