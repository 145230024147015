/* eslint-disable class-methods-use-this */
import {
  Extension,
  accessResponse,
  androidAppIds,
  basicResponse,
  command,
  durtApiResponseType,
  durtEvents,
  extensionResponseType,
  infoResponse,
} from 'client/services/durtConstants';
import { digest, makePkceCode } from 'client/util/pkceGenerator';
import {
  sendMessageToAndroidApp,
  sendMessageToChromeExtension,
  sendMessageToFirefoxExtension,
  sendMessageToSafariExtension,
} from 'client/util/message';
import Util from 'client/util/Util';
import api from 'client/api';
import durtEventLogger from 'client/services/durtEventLogger';

import type { DurtAccess } from 'client/types/Auth';

const { user: userApi } = api;

const getAndroidIdByEnv = () => {
  const env = Util.getEnvironment();
  return androidAppIds[env];
};

export const extensions: {[name: string]: Extension} = {
  chrome: {
    id: 'jdoahkhfkeipblhbhppmcbdgapeoaopa',
    send: sendMessageToChromeExtension,
  },
  android: {
    id: getAndroidIdByEnv(),
    send: sendMessageToAndroidApp,
  },
  firefox: {
    id: 'go@jumpcloud.com',
    send: sendMessageToFirefoxExtension,
  },
  safari: {
    id: 'com.jumpcloud.JumpCloud-Protect.safari-extension (N985MXSH85)',
    send: sendMessageToSafariExtension,
  },
};

export class DurtService {
  private extension: Extension | undefined;

  async getInfo(): Promise<infoResponse> {
    const info = await Promise.race([
      this.sendMessage<infoResponse>({ cmd: command.info }),
      Util.delay(3000),
    ]);

    const validResults = [
      durtApiResponseType.success,
      durtApiResponseType.userNotRegisteredError,
    ];

    if (validResults.includes(info?.result)) {
      return info;
    }

    const errorResult = info?.result || durtApiResponseType.unexpectedError;

    durtEventLogger.emitAction(
      'jcgo-access',
      { message: durtEvents.info.error, result: 'error', response: errorResult },
    );

    throw new Error(errorResult);
  }

  async signin(accessData: accessResponse): Promise<void> {
    if (accessData.result === durtApiResponseType.success) {
      await userApi.loginDURT({
        sessionToken: accessData.session,
        sessionTokenCode: accessData.pkceKey,
      });

      durtEventLogger.emitAction(
        'jcgo-access',
        { message: durtEvents.signin.success, result: 'success', response: accessData.result },
      );
    } else {
      durtEventLogger.emitAction(
        'jcgo-access',
        { message: durtEvents.signin.error, result: 'error', response: accessData.result },
      );
    }
    // @TODO: what do we do here?
    // accessData.result === durtApiResponseType.userNotRegisteredError
  }

  // Invalidates DURT and destroys user session
  async signout(): Promise<void> {
    // Timeout logout call incase it hangs
    await Promise.race([this.logout(), Util.delay(3000)]);
    return userApi.logoutDURT();
  }

  // eslint-disable-next-line max-len
  async getAccess(userVerificationRequired = false, stateId = ''): Promise<accessResponse> {
    try {
      const pkceKey = makePkceCode();
      const pkceChallenge = await digest(pkceKey);
      const accessArgs:DurtAccess = {
        cmd: command.access,
        pkce: pkceChallenge,
      };

      if (stateId?.length) {
        accessArgs.state = stateId;
        accessArgs.uv = { required: userVerificationRequired };
      }

      const access = await this.sendMessage<accessResponse>(accessArgs);

      const validResults = [
        durtApiResponseType.success,
        durtApiResponseType.userNotRegisteredError,
      ];

      if (validResults.includes(access.result)) {
        return {
          ...access,
          pkceKey,
        };
      }

      durtEventLogger.emitAction(
        'jcgo-access',
        { message: durtEvents.access.error, result: 'error', response: access.result },
      );

      throw new Error(access.result);
    } catch {
      throw new Error(durtApiResponseType.unexpectedError);
    }
  }

  async register(idToken:string): Promise<void> {
    const registerResponse = await this.sendMessage<basicResponse>({
      cmd: command.register,
      token: idToken,
    });

    if (registerResponse.result !== durtApiResponseType.success) {
      durtEventLogger.emitAction(
        'jcgo-register',
        { message: durtEvents.register.error, result: 'error', response: registerResponse.result },
      );

      throw new Error(registerResponse.result);
    } else {
      durtEventLogger.emitAction(
        'jcgo-register',
        { message: durtEvents.register.success, result: 'success', response: registerResponse.result },
      );
    }
  }

  async hasExtension(): Promise<boolean> {
    try {
      if (!this.extension) {
        this.extension = await this.getExtension();
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  // Invalidates a user's DURT via browser extension
  async logout(): Promise<basicResponse> {
    const logout = await this.sendMessage<basicResponse>({ cmd: command.logout });

    if (logout.result !== durtApiResponseType.success) {
      throw new Error(logout.result);
    }

    return logout;
  }

  private async sendMessage<T>(payload: any) {
    if (!this.extension) {
      this.extension = await this.getExtension();
    }

    return this.extension.send<T>(this.extension.id, payload);
  }

  private async getExtension() {
    if (await this.hasAndroidApp()) {
      return extensions.android;
    }
    if (await this.hasBrowserExtension('chrome')) {
      return extensions.chrome;
    }
    if (await this.hasBrowserExtension('firefox')) {
      return extensions.firefox;
    }
    if (await this.hasSafariExtension()) {
      return extensions.safari;
    }

    throw new Error('durt extension error: not found');
  }

  private async hasBrowserExtension(browser: string) {
    try {
      const response = await extensions[browser].send<basicResponse>(extensions[browser].id, { cmd: 'be_version' });
      return response.result === extensionResponseType.success;
    } catch (error) {
      return false;
    }
  }

  // TODO: add 'be_version' to safari so extension checks can be unified
  private async hasSafariExtension() {
    if (navigator.vendor !== 'Apple Computer, Inc.') return false;
    try {
      const response = await Promise.race([
        extensions.safari.send<basicResponse>(
          extensions.safari.id,
          { cmd: command.info },
        ),
        Util.delay(200), // timeout the safari extension check
      ]);
      return response.result === extensionResponseType.success;
    } catch (error) {
      return false;
    }
  }

  private async hasAndroidApp(): Promise<boolean> {
    try {
      const relatedApps = await (navigator as any).getInstalledRelatedApps();
      const appPackageName = 'com.jumpcloud.JumpCloud_Protect';

      if (relatedApps.length) {
        const androidApp = relatedApps.find((app: any) => app.id.includes(appPackageName));
        if (androidApp) {
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }
}

// DurtService Singleton
export default new DurtService();
