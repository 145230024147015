
// Libraries
import { defineComponent, toRef, watch } from 'vue';

// Components
import SsoStepUpController from 'client/containers/user/SsoStepUp/SsoStepUpController.vue';

// Utils
import mfaFactorBuilder from 'client/containers/user/MfaComponents/mfaUtils/mfaFactorBuilder';

// Services
import LocalStorageService from 'client/util/LocalStorageService';
import routerService from 'client/services/routerService';

// Constants & Types
import { LocalStorageConsts, LoginConsts } from 'client/util/Constants';
import MfaConstants from 'client/containers/Login/MfaConstants';
import type { MfaFactor } from 'client/types/Auth';

const {
  clientType,
} = LoginConsts;
const USER = clientType.user;

const { mfaStatus } = MfaConstants;
const { previousMfaMethod } = LocalStorageConsts;

export default defineComponent({
  name: 'SsoStepUpWrapper',

  data() {
    return {
      errorMessage: '',
    };
  },

  props: {
    factorsRaw: {
      type: String,
      required: true,
    },
    mfaType: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const mfaType = toRef(props, 'mfaType');

    // AUTH-7610: Need to set factor before successful mfa attempt
    // because redirect happens server side
    watch(mfaType, (newValue) => {
      LocalStorageService.setItem(previousMfaMethod, newValue);
    });
  },

  components: {
    SsoStepUpController,
  },

  computed: {
    controllingQueryParams(): { factors?: string } {
      // this ensures we don't lose query params as we navigate
      const factorsRawParam = this.factorsRaw ? { factors: this.factorsRaw } : {};

      return {
        ...factorsRawParam,
      };
    },

    factors(): MfaFactor[] {
      return this.getIdFirstFactors();
    },

    factorsParsed(): { clientType: string, status: string, type: string }[] {
      return this.factorsRaw
        .split(',')
        .filter((factor) => factor)
        .map((factorName) => ({
          type: factorName,
          status: mfaStatus.available,
          clientType: this.clientType,
        }));
    },

    clientType() {
      return USER;
    },
  },

  methods: {
    getIdFirstFactors() {
      const mfa = { factors: this.factorsParsed };
      const { controllingQueryParams } = this;
      const options = { loginType: 'ssoStepUp', controllingQueryParams };

      return mfaFactorBuilder(mfa, options);
    },

    handleTooManyFailedAttempts(__: any, errorMessage: string) {
      // will only reach this when calling `changeComponent`, indicating a 403 error
      routerService.goToUserLogin({
        params: { error: errorMessage },
      });
    },

    resetErrorMessage() {
      this.setErrorMessage('');
    },

    setErrorMessage(message: string) {
      this.errorMessage = message;
    },
  },
});
