<template>
  <div :class="$style.container">
    <div
      :class="{
        [$style.animationWrapper]: true,
        [$style.useMargin]: useMargin
      }"
    >
      <h1 :class="$style.header">
        Passwordless Login
      </h1>

      <img
        alt="JumpCloud Go"
        :class="$style.jcLogo"
        src="~public/img/jc-go-animation.svg"
      >

      <EmailBadge
        v-if="userEmail"
        :class="$style.userEmail"
        :email="userEmail"
      />

      <transition
        appear
        :class="$style.checklist"
        mode="out-in"
        name="fade"
        :enterClass="$style.fadeEnterFrom"
        :enterActiveClass="$style.fadeEnterActive"
        :enterToClass="$style.fadeEnterTo"
        :leaveClass="$style.fadeLeaveFrom"
        :leaveActiveClass="$style.fadeLeaveActive"
        :leaveToClass="$style.fadeLeaveTo"
      >
        <component
          :class="$style.step"
          :is="activeComponent"
        />
      </transition>

      <transition
        appear
        name="fadeIn"
        :enterClass="$style.fadeInEnterFrom"
        :enterActiveClass="$style.fadeInEnterActive"
        :enterToClass="$style.fadeInEnterTo"
      >
        <p
          v-if="shouldShowRedirect"
          :class="$style.textRedirect"
        >
          If you are not redirected click
          <a
            :class="$style.link"
            href="/login?autoGo=false"
          >
            here
          </a>
          to log in.
        </p>
      </transition>
      <LogoIcon
        v-if="shouldShowJCLogo"
        :class="$style.footerLogo"
      />
    </div>
  </div>
</template>

<script>
import EmailBadge from 'client/components/EmailBadge.vue';
import JCGoSecureStep from 'client/components/JCGoSecureStep.vue';
import JCGoVerifyStep from 'client/components/JCGoVerifyStep.vue';
import LogoIcon from 'client/components/LogoIcon.vue';

export default {
  name: 'JumpcloudGoPageLoader',

  components: {
    EmailBadge,
    JCGoVerifyStep,
    LogoIcon,
    JCGoSecureStep,
  },

  props: {
    userEmail: {
      type: String,
      default: '',
    },
    useMargin: {
      type: Boolean,
      default: true,
    },
    shouldShowJCLogo: {
      type: Boolean,
    },
  },

  data() {
    return {
      activeComponent: JCGoVerifyStep,
      shouldShowRedirect: false,
    };
  },

  mounted() {
    this.componentTimeout = setTimeout(() => {
      this.activeComponent = JCGoSecureStep;
    }, 1000);

    this.redirectTimout = setTimeout(() => {
      this.shouldShowRedirect = true;
    }, 5000);
  },

  beforeDestroy() {
    clearTimeout(this.componentTimeout);
    clearTimeout(this.redirectTimout);
  },
};
</script>

<style scoped module>
@import '../css/login-components.css';

@value (jcPrimary) from 'css/brand-colors.css';

.container {
  background-color: #f0fcfd;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999999;
}

.header {
  margin: 1rem 0 -3rem 0;
}

.animationWrapper {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  padding: 2rem;
}

.animationWrapper.useMargin {
  margin: 6rem auto;
}

.textRedirect {
  font-weight: 600;
  margin: 2rem 0 0 0;
  text-align: center;
}

.link {
  color: jcPrimary;
  font-size: 0.875rem;
  text-decoration: none;
  transition: all 0.25s;
}

.link:hover {
  filter: brightness(85%);
  transition: all 0.25s;
}

.jcLogo {
  height: 300px;
  object-fit: cover;
}

.userEmail {
  display: block;
  font-size: 0.9rem;
  margin-top: -4rem;
  max-width: 20rem;
}

.checklist {
  padding: 0;
}

.step {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: 600;
  justify-content: center;
  margin: 0.5rem 0 1.25rem 0;
  width: 19rem;
}

/** start transition classes */

.fadeEnterFrom,
.fadeInEnterFrom,
.fadeLeaveTo {
  opacity: 0;
}

.fadeEnterTo,
.fadeInEnterTo,
.fadeLeaveFrom {
  opacity: 1;
}

.step.fadeEnterActive {
  -moz-transition: opacity 500ms ease-out 200ms;
  -webkit-transition: opacity 500ms ease-out 200ms;
  transition: opacity 500ms ease-out 200ms;
}

.step.fadeLeaveActive {
  -moz-transition: opacity 250ms ease-out;
  -webkit-transition: opacity 250ms ease-out;
  transition: opacity 250ms ease-out;
}

.textRedirect.fadeInEnterActive {
  -moz-transition: opacity 250ms ease-out;
  -webkit-transition: opacity 250ms ease-out;
  transition: opacity 250ms ease-out;
}

/** end transition classes */

@media (max-width: 1058px) {
  .container {
    background-color: #fff;
    padding: 0;
  }

  .animationWrapper {
    margin-top: 3rem;
  }

  .footerLogo {
    bottom: 2rem;
    display: block;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
  }
}

@media (max-width: 1058px) and (orientation: landscape) {
  .animationWrapper {
    margin-top: 0;
  }

  .footerLogo {
    display: block;
    margin: 3rem auto 0 auto;
    position: static;
  }
}
</style>
