<template>
  <div :class="$style.formContainer">
    <transitionGroup
      :enterClass="$style.fadeEnter"
      :enterActiveClass="$style.fadeEnterActive"
      :leaveActiveClass="$style.fadeLeaveActive"
      :leaveToClass="$style.fadeLeaveTo"
    >
      <LoginCard
        v-if="!success"
        :header="headerText"
        key="form"
      >
        <Alert :message="errorMessage" />

        <form>
          <LoginTextField
            v-model="email"
            :required="true"
            :submitOnEnter="submit"
            autoFocus
            label="Email"
            name="email"
            placeholder="Email Address"
            type="email"
            vvName="email"
          />
          <LoginActionButton
            :action="submit"
            :isLoading="isLoading"
            type="button"
          >
            Set Password
          </LoginActionButton>
        </form>
      </LoginCard>

      <LoginCard
        v-if="success"
        :header="headerText"
        class="email-sent-content"
        key="confirmation"
      >
        <ul :class="$style.directionsList">
          <li :class="$style.directions">
            A confirmation email has been sent (email address must be registered with JumpCloud).
            <a
              :class="$style.link"
              :href="resetEmail"
            >
              Enter different email
            </a>
          </li>
          <li :class="$style.directions">
            Please follow the instructions in the email to reset your password.
          </li>
          <li :class="$style.directions">
            Allow up to 10 minutes for the email to arrive in your inbox.
          </li>
        </ul>
        <LoginActionButton
          :action="goToLogin"
        >
          Return to Login
        </LoginActionButton>
      </LoginCard>
    </transitionGroup>

    <LogoIcon />
  </div>
</template>

<script>
import { AdminRoutes, UserRoutes } from 'client/routes/RouteGenerator';
import { mapStores } from 'pinia';
import LoginCard from 'client/components/LoginCard.vue';
import UserLoginStore from 'client/stores/UserLoginStore';
import api from 'client/api';
import loginUtil from 'client/components/mixins/loginUtil';
import routerService from 'client/services/routerService';

const resetAdminRoute = AdminRoutes.resetPassword;
const resetUserRoute = UserRoutes.resetPassword;

export default {
  name: 'RequestPasswordReset',

  components: {
    LoginCard,
  },

  mixins: [loginUtil],

  props: {
    clientType: {
      type: String,
      required: true,
    },
    context: {
      type: String,
      default: undefined,
    },
    redirectTo: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    email: '',
    success: false,

  }),

  mounted() {
    if (this.clientType === 'user') {
      this.email = this.userLoginStore.email;
    }
  },

  computed: {
    ...mapStores(UserLoginStore),

    resetEmail() {
      return this.isAdmin() ? resetAdminRoute : resetUserRoute;
    },

    headerText() {
      return this.isAdmin() ? 'Set Administrator Password' : 'Set User Password';
    },
  },

  methods: {
    goToLogin() {
      if (this.isAdmin()) {
        routerService.goToAdminLogin();
      } else {
        const query = this.context && this.redirectTo
          ? { context: this.context, redirectTo: this.redirectTo } : {};
        routerService.goToUserLogin({ query });
      }
    },

    request() {
      api[this.clientType].requestPasswordReset({ email: this.email })
        .then(() => {
          this.isLoading = false;
          this.success = true;
        }).catch(() => {
          this.isLoading = false;
          this.errorMessage = 'There was an error handling your request.';
        });
    },
  },
};
</script>

<style module>
@import '../css/login-components.css';

@value (jcInformational900) from 'css/brand-colors.css';

.directionsList {
  margin-top: 20px;
  text-align: left;
}

.directions {
  margin-bottom: 1.5em;
}

.fadeEnterActive {
  transition: opacity 0.35s 0.35s;
}

.fadeLeaveActive {
  transition: opacity 0.35s;
}

.fadeEnter,
.fadeLeaveTo {
  opacity: 0;
}

link {
  color: jcInformational900;
}
</style>
