
// Libraries
import { PropType, defineComponent } from 'vue';

// Components
import EmailBadge from 'client/components/EmailBadge.vue';
import LoginAlert from 'client/components/LoginAlert.vue';
import LoginCard from 'client/components/LoginCard.vue';
import LogoIcon from 'client/components/LogoIcon.vue';

// Constants
import { useLoginLabels } from 'client/util/Constants/LoginLabels';
import { useMfaLabels } from 'client/util/Constants/MfaLabels';

// Store
import { mapStores } from 'pinia';
import UserLoginStore from 'client/stores/UserLoginStore';

// Util
import RequestError from 'client/services/response/RequestError';
import userLoginService from 'client/services/userLoginService';

export default defineComponent({
  name: 'AuthView',

  components: {
    EmailBadge,
    LoginAlert,
    LoginCard,
    LogoIcon,
  },

  props: {
    context: {
      type: String,
      default: undefined,
    },
    errorMessageDisplayDuration: {
      type: Number,
      default: undefined,
    },
    errorMessage: {
      type: String,
      required: true,
    },
    goToAdminLogin: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
    hasPendingErrorMessage: Boolean,
    headerText: {
      type: String,
      required: true,
    },
    shouldShowAdminRedirectInError: Boolean,
    mfaType: {
      type: String,
      default: '',
    },
    shouldAllowComponentChange: Boolean,
    shouldHideEmailBadge: Boolean,
  },

  setup() {
    const loginLabels = useLoginLabels();
    const mfaLabels = useMfaLabels();

    return {
      loginLabels,
      mfaLabels,
    };
  },

  computed: {
    ...mapStores(UserLoginStore),

    helpText(): string {
      const help = (
        this.mfaLabels as { [key: string]: any}
      )[this.mfaType]?.help;

      return help || '';
    },

    durationMessage(): string {
      const secondValue = this.errorMessageDisplayDuration === 1 ? 'second' : 'seconds';
      return `${this.errorMessageDisplayDuration} ${secondValue}`;
    },
  },

  methods: {
    handleChangeEmailAddress() {
      // during oauth with login_hint, user won't be able to change password in login-ui
      const isOauthFlowWithLoginHint = this.context === 'oauth' && !!this.$route.query.login_hint;

      if (isOauthFlowWithLoginHint) {
        userLoginService.oauthChangeEmail().catch((error: RequestError) => {
          this.$emit('error', error);
        });
      } else {
        // empty step signifies email component
        this.$emit('changeComponent', '');
      }
    },

    handleJumpcloudGoClick() {
      this.$emit('jumpcloudGoClick');
    },
  },
});
